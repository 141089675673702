.btnVldt{
    border: 1px solid #444ce7;
    background-color: #444ce7;
    border-radius: 8px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    gap: 8px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 30px;
}
.btnVldt:hover{
    background-color: #3538CD;
    border: 1px solid #3538CD;
    color: #FFFFFF;
}