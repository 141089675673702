@font-face {
  font-family: Inter-Bold;
  src: url(../../../public/Inter/static/Inter-Bold.ttf);
}
@font-face {
  font-family: Inter-SemiBold;
  src: url(../../../public/Inter/static/Inter-SemiBold.ttf);
}
@font-face {
  font-family: Inter-Medium;
  src: url(../../../public/Inter/static/Inter-Medium.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(../../../public/Inter/static/Inter-Regular.ttf);
}
/* splashScreen style */

.mainWrap {
  background-color: #f5f8ff;
  min-height: 100vh;
  padding-top: 20px;
}
.padIn {
  padding: 24px 32px 40px 32px;
}
.logoCntr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.logoStyle {
  max-width: 100%;
}
.splashScreen {
  height: 535px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homePad {
  padding: 20px 80px 100px 80px !important;
}
.padTB {
  padding: 40px 0;
}
.hdg {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Inter-Bold";
  color: #344054;
}
.subHdg {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: "Inter-SemiBold";
  color: #344054;
}
.subTxt {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Inter-Regular";
  color: #344054;
}
.subTxtRW {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #344054;
}
.cntr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.VWOcard {
  position: relative;
  width: 160px;
  height: 160px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
.iconBtm {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.VWOcard p {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: "Inter-SemiBold";
  color: #667085;
}
.rot1 {
  transform: rotate(-5deg);
}
.rot2 {
  transform: rotate(-2deg);
}
.rot3 {
  transform: rotate(2deg);
}
.rot4 {
  transform: rotate(-3deg);
}
.orngBrdr {
  border: 2px solid #f97066;
}
.smHgt {
  height: 120px !important;
}
.bluBrdr {
  border: 2px solid #8098f9;
}
.indctor {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #f5f8ff;
}

/* loginScreen style */

.clWrap {
  display: flex;
  background-color: #ffffff;
  height: 100vh;
}
@media screen and (max-height: 670px) {
  .clWrap {
    height: 113vh;
  }
}
@media screen and (max-height: 570px) {
  .clWrap {
    height: 140vh;
  }
}
@media screen and (max-height: 470px) {
  .clWrap {
    height: 150vh;
  }
}
.clLft-side {
  width: 340px;
  background-color: #f9fafb;
  padding: 40px 60px 40px 60px;
}
.clLogoStyle {
  width: 100px;
  /* height: 33px; */
  /* max-width: 100%; */
  height: auto;
  object-fit: contain;
  position: fixed;
}
.illustrationContainer {
  bottom: 50px;
  position: fixed;
}

.illustImg {
  /* max-width: 100%; */
  height: auto;
}
.formSpc {
  flex-grow: 1;
  padding: 80px 120px 80px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginCode {
  width: 360px;
}
.formArea {
  height: auto;
  margin-bottom: 10px;
}
.loginCode p {
  font-size: 27px;
  font-weight: 700;
  line-height: 38px;
  font-family: "Inter-Bold";
  color: #344054;
}
.loginCode b {
  color: #444ce7;
  font-family: "Inter-Bold";
}

.loginCode label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #344054;
}

.loginCode .formArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.loginCode .formArea input[type="text"].error {
  border: 1px solid #fda29b;
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px #1018280d;
}

.loginCode .formArea input[type="text"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.input-group {
  position: relative;
  display: inline-block;
}

.keyIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}
.helpIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  right: 8px;
}

#codeInput {
  padding-left: 33px;
  padding-right: 33px;
}

.loginCode .instructionTxt {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Inter-Regular";
  color: #475467;
}
.loginCode .instructionTxtError {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #f04438;
}
.btnArea {
  display: flex;
  justify-content: center;
}

.btnVldt {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #444ce7;
  padding: 10px 16px;
  gap: 8px;
  background-color: #444ce7;
}
.btnVldt.disabled {
  opacity: 0.4;
}

.btnVldt p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #ffffff;
  margin: 0;
}

.arrowIconStyl {
  width: 20px;
  height: 20px;
}
.CPD-Area {
  padding: 16px 8px 16px 8px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: var(--30-black, #d0d5dd);
  gap: 8px;
  margin-bottom: 20px;
}
.CPD-Area .instructionTxt b {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: "Inter-SemiBold";
  color: #475467;
}
.dataFormArea {
  padding: 0 5px;
}
.dataFormArea .instructionTxt {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #475467;
}
.loginCode .dataFormArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .dataFormArea input[type="text"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
#nameInput,
#emailInput,
#contactInput {
  padding-left: 33px;
  padding-right: 33px;
}
.userIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}
.emailIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}
.contactIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}

/* AdditionalInformation style */

.addInfsubTxt p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #101828;
}
.loginCode .addInfrmtnArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .addInfrmtnArea input[type="text"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.loginCode .addInfrmtnArea select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.loginCode .addInfrmtnArea select:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}

.aiWrap {
  display: flex;
  background-color: #ffffff;
}
.loginCode .addInfrmtnArea input[type="date"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .addInfrmtnArea input[type="date"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.loginCode .addInfrmtnArea input[type="file"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .addInfrmtnArea input[type="file"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.uploadIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  right: 13px !important;
}
/* #AvailableFromInput::-webkit-calendar-picker-indicator {
  display: none;
}
.calendarIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  right: 13px !important;
} */

/* CandidateList style */

.canLisWrap {
  display: flex;
  min-height: 100vh;
  height: auto;
  width: auto;
  background-color: #f2f4f7;
}
.canLisSideBar {
  background-color: #ffffff;
  width: 16%;
  padding: 40px 0px 0px 0px;
  gap: 40px;
  border-right: 0.5px solid var(--lightest-Blue, #d7e2ff);
  display: flex;
  flex-direction: column;
  height: auto;
}
.canLisLogoStyle {
  width: 100px;
  /* height: 33px; */
  /* max-width: 100%; */
  height: auto;
  object-fit: contain;
}
.canLisLogoArea {
  display: flex;
  /* justify-content: center; */
  padding: 0px 0px 24px 32px;
  gap: 10px;
}
.sideBarMenuArea {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sideBarMenu {
  padding: 16px;
  gap: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.sideBarMenu.active {
  background-color: #fcfaff;
  border-right: 4px solid var(--Brand-Blue, #081da5);
}

.sideBarMenu p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #344054;
  margin: 0;
}
.sideBarMenu.active p {
  font-weight: 500;
  color: #081da5;
}
.sideBarLogoStyle {
  width: 16px;
  height: 16px;
}
.sideBarBottomBtn {
  padding: 16px;
  gap: 16px;
  border-top: 1px solid #aec0ff80;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: auto;
}
.canLisContentWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fullwidth {
  width: 100%;
}
.canLisNavBar {
  background-color: #ffffff;
  padding: 16px 48px 16px 48px;
  gap: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 72px; */
  height: fit-content;
  width: 100%;
}
.OrgArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.OrgArea p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #344054;
  margin: 0;
}
.OrgLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #aec0ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OrgLogo p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #ffffff;
  margin: 0;
}
.profileArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.profileBtnArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.profileBtnStyle {
  width: 24px;
  height: 24px;
}
.profileDropDown {
  position: relative;
  display: inline-block;
}
.profileImgStyle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.profileImgStyle.active {
  box-shadow: 0px 0px 0px 3px rgba(130, 129, 129, 0.2);
}
.dropdownMenu {
  display: block;
  position: absolute;
  right: 0;
  background-color: #ffffff;
  min-width: 240px;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  z-index: 1;
  margin-top: 8px;
}
.dropdownMenuOptionBox {
  padding: 4px 0px 4px 0px;
  border-bottom: 1px solid #eaecf0;
}
.optnBtnArea {
  padding: 2px 4px 2px 4px;
  border-bottom: 1px solid #eaecf0;
}
.dropdownMenu a {
  text-decoration: none;
  display: block;
}
.dropdownMenu a:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.optnInside {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px 9px 10px;
}
.optnInside p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Inter-Regular";
  color: #667085;
  margin: 0;
}
.optnLogoTxt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.optnLogoTxt p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #344054;
  margin: 0;
  text-align: center;
}
.profileOptnIcon {
  height: 25px;
  width: 25px;
}

.accessCodeRedirect {
  color: blue;
}

.dropdownMenuProfile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 16px 12px 16px;
  border-bottom: 1px solid #eaecf0;
  position: relative;
}
.profImgContainer {
  position: relative;
  display: inline-block;
}

.OnlineIndicatorStyle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
}
.profTxt {
  display: flex;
  flex-direction: column;
}

.hdProfTxt {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #344054;
  margin: 0;
}
.subProfTxt {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #475467;
  margin: 0;
}
.hidden {
  display: none;
}
.BarIconTopArea {
  display: flex;
  align-items: center;
  justify-content: center;
}
.candListBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
  margin: 20px;
  padding: 10px;
  height: 100%;
}
.grouplisttable {
  max-width: 100vw;
  max-height: 70vh;
  overflow-x: scroll;
  .ant-pagination{
  display: none;
}
}
/* .grouplisttable-alljobs {
  .ant-table-content {
    margin-inline: 40px;
    & thead {
      & tr {
        th {
          font-size: 20px;
        }
      }
    }
  }
} */
span.addbackgroundcolor {
  display: inline-block;
  margin-bottom: 8px;
}
.addbackgroundcolor {
  background-color: #a1b0b9;
  font-weight: 500;
}
.ant-pagination {
  display: none;
  position: absolute;
  display: none!important;

}
.btn-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.toggle-container {
  display: flex;
  cursor: pointer;
}

.toggle-switch {
  width: 50px !important;
  height: 25px;
  border-radius: 25px;
  background-color: #ccc;
  position: relative;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.toggle-switch.active {
  background-color: rgb(67, 133, 218);
}

.toggle-circle {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 1px;
  left: 0px;
  transition: transform 0.3s;
}
.link-text:hover {
  color: #0000ee;
  cursor: pointer;
}
.toggle-switch.active .toggle-circle {
  transform: translateX(26px);
}

.toggle-label {
  color: #000;
  font-size: 16px;
}
.candListHdr {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px 20px 24px;
  gap: 12px;
  border-bottom: 1px solid #eaecf0;
}
.canLisTxtStyl {
  display: flex;
  align-items: center;
}
.canLisTxtStyl p {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  font-family: "Inter-SemiBold";
  color: #101828;
  margin: 0;
  text-align: center;
}
.canLisSearchArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.boxIcon {
  width: 20px;
  height: 20px;
}
.searchBox {
  display: flex;
  align-items: center;
  width: 180px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 14px 10px 14px;
  gap: 8px;
  cursor: pointer;
}
.searchInput {
  flex-grow: 1;
  border: none;
  outline: none;
  width: 100px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #667085;
  cursor: pointer;
}
.search-input {
  border: none;
  outline: none;
  width: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #667085;
  cursor: pointer;
}
.canLisIconBox {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
}
.uploadBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #444ce7;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 16px 10px 16px;
  background-color: #444ce7;
  gap: 8px;
}
.uploadBtn p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #ffffff;
  margin: 0;
}
.tableWrap {
  height: 410px;
  overflow: auto;
  max-height: 410px;
}
.customTable {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.rowTxtChkBxAC {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 120px;
}
.rowTxtChkBx {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.customCheckbox {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  position: relative;
}

.customCheckbox:checked {
  border-color: #081da5;
  background-color: #d7e0ff;
}

.customCheckbox:checked::before {
  content: "✓";
  font-size: 14px;
  color: #081da5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.customTable thead tr {
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  white-space: nowrap;
}
.customTable tbody tr {
  border-bottom: 1px solid #eaecf0;
  white-space: nowrap;
}

.customTable th {
  padding: 12px 17px 12px 17px;
  gap: 1px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  font-family: "Inter-Medium";
  color: #475467;
}
.customTable td {
  padding: 15px 17px 15px 17px;
  gap: 1px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #475467;
}
.nameBold {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #101828;
}
.customTable tbody tr:hover {
  background-color: #f9fafb;
}
.rowSelectorArea {
  padding: 12px 17px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rowSelector {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.rowSelector label {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #344054;
}

.rowSelector select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s;
}
.rowSelector select:focus {
  border-color: #081da5;
}
.paginationButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 8px 14px 8px 14px;
  gap: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  cursor: pointer;
}
.paginationButton p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #344054;
  margin: 0;
}
.pageNumbers {
  display: flex;
  gap: 2px;
}

.pageNumber {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #475467;
}
.pageNumber:hover {
  background: #f9fafb;
}

/* Modal styles */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalArea {
  background-color: #fff;
  width: 800px;
  border-radius: 16px;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow: hidden;
}
.modalTitleArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaecf0;
  padding: 16px 24px 16px 24px;
  gap: 8px;
}
.modalTitleIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.modalTitleArea p {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  font-family: "Inter-SemiBold";
  color: #101828;
  margin: 0;
}
.helpIconModal {
  width: 16px;
  height: 16px;
}
.modalClose {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.modalContent::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.modalContent {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modalContent {
  padding: 16px 32px 16px 32px;
  overflow-y: auto;
  gap: 24px;
}
.halfSec {
  width: 50%;
}
.advncSrchArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.advncSrchArea select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #667085;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.advncSrchArea select:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.advncSrchArea label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #344054;
  margin-bottom: 8px;
}
.advncSrchArea input[type="date"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #667085;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.advncSrchArea input[type="date"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.twoCalndrArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.advncSrchArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #667085;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.advncSrchArea input[type="text"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.checkBoxTxtArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}
.checkBoxTxtArea p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #475467;
  margin: 0;
}
.modalFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #eaecf0;
  gap: 19px;
  padding: 16px 32px 16px 32px;
}
.modalFooterBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.resetBtn {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 8px 14px 8px 14px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #344054;
}
.searchBtn {
  border-radius: 8px;
  border: 1px solid #444ce7;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 8px 14px 8px 14px;
  background-color: #444ce7;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #ffffff;
}
/* .antic-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content{
  table{
    thead{
      tr{
        th{
          width: 100px;
        }
      }
    }
  }
} */
.antic-table {
  .ant-pagination {
    display: none;
  }
}
.ant-table-content {
  thead {
    tr {
      th {
        text-wrap: nowrap;
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 10px;
      }
    }
  }
}
.group-table {
  th:nth-child(2),
  th:nth-child(3) {
    text-align: center;
  }
  td:nth-child(2),
  td:nth-child(3) {
    text-align: center;
    input {
      width: 50px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .canLisWrap {
    width: max-content;
  }
  .canLisSideBar {
    width: 215px;
  }
  .canLisContentWrap {
    width: 100%;
    max-width: max-content;
  }
  .candListBox {
    width: 1000px;
  }
}
@media screen and (max-width: 991px) {
  .illustImg {
    /* max-width: 100%; */
    width: 330px !important;
    height: auto;
  }
  .homePad {
    padding: 0 20px !important;
  }
  .formSpc {
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .clLft-side {
    padding: 30px;
  }
  .illustImg {
    /* max-width: 100%; */
    width: 250px !important;
    height: auto;
  }
}

/* mobile view */
@media screen and (max-width: 576px) {
  .mainWrap {
    background-color: #eef4ff;
    min-height: 100vh;
    padding-top: 0;
  }
  .logoCntr {
    margin-bottom: 10px;
  }
  .splashScreen {
    height: 650px;
    display: block;
    align-items: initial;
    justify-content: initial;
  }
  .padTB {
    padding: 20px 0;
  }
  .homePad {
    padding: 0 10px !important;
  }
  .subTxt {
    font-size: 16px;
    line-height: 24px;
  }
  .formArea {
    height: auto;
  }
  .mobBtn {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    border: 1px 0px 0px 0px #eaecf0;
  }
  .formSpc {
    padding: 15px;
  }
  .btnVldtMob {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #444ce7;
    padding: 12px 20px;
    gap: 8px;
    background-color: #444ce7;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .btnVldtMob p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: "Inter-SemiBold";
    color: #ffffff;
    margin: 0;
  }
  .subHdg {
    font-size: 20px;
    line-height: 30px;
  }
  .mobViewPT {
    padding: 0 10px;
  }
  .logoStyleMobPT {
    width: 32px;
    height: 32px;
  }
  .VWOcard {
    width: 120px;
    height: 120px;
  }
  .swipeIconStyleMobPT {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  .swpRgt {
    display: flex;
    align-items: center;
    /* margin: 10px 0; */
    margin: 0 0 10px 0;
  }
  .swpLft {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
  }
  .swpTxt {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: "Inter-Regular";
    color: #0c111d;
  }
  .swpTxtMob {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Inter-Regular";
    color: #344054;
  }
  .subTxtRW {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-family: "Inter-Regular";
    color: #344054;
  }
  .clWrap {
    display: block;
    background-color: #ffffff;
    height: 100vh;
  }
  .clLft-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 200px;
    background-color: #f9fafb;
    padding: 24px 20px 24px 20px;
  }

  .illustrationContainer {
    position: static;
    margin-top: 50px;
  }

  .clLogoStyleMob {
    left: 20px !important;
    position: absolute;
    display: flex;
  }
  .clLogoStyle {
    position: static;
  }
  .illustImg {
    max-width: 100%;
    height: 120px;
  }
  .btnVldt {
    width: 100%;
  }
  .indctor {
    bottom: 70px;
    width: 100%;
    padding: 10px;
    background-color: #eef4ff;
  }
  .aiWrap {
    display: block;
    background-color: #ffffff;
  }
  .loginCode {
    width: 440px;
  }
}
@media (min-width: 576px) {
  .webViewPT {
    display: block;
  }
  .mobViewPT {
    display: none;
  }
}
@media (max-width: 575.99px) {
  .mobBtn {
    display: block;
  }
  .webViewPT {
    display: none;
  }
  .mobViewPT {
    display: block;
  }
}
.getPerc {
  width: 100px;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper tfoot > tr > td {
  padding: 2px 7px!important;
  
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-column-sorter {
  margin-inline-start: 1px;
}
.ant-table-content {
  & tbody {
    & tr {
      td {
        font-size: 11px;
      }
    }
  }
  h5 {
    /* font-size: 1.1rem; */
    font-size: 11px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bread-active {
  display: flex;
}
.bread-active:empty {
  display: none;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-empty-normal .ant-empty-image,
:where(.css-dev-only-do-not-override-98ntnt).ant-empty-normal
  .ant-empty-description {
  margin-left: -70vw;
}

/* .grouplist-table {
  .ant-table-content {
    & thead {
      & tr {
        th {
          text-wrap: nowrap;
          font-size: 20px;
        }
      }
    }
  }
  .ant-table-content {
    & tbody {
        & tr {
            td {
                font-size: 17px;
            }
        }
    }
    h5{
      font-size: 17px;
    }
}
} */

:where(.css-98ntnt).ant-table-wrapper .ant-table-cell, :where(.css-98ntnt).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-98ntnt).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-98ntnt).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-98ntnt).ant-table-wrapper tfoot>tr>th, :where(.css-98ntnt).ant-table-wrapper tfoot>tr>td {
  padding: 2px 7px!important;
}
